import * as React from 'react'
import styled from 'styled-components'
import { MailOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { Body, HSpaced, Block, HideOn } from '@thesisedu/web/dist'
import {
  BorderInput,
  BrochureCard,
  Container,
  GlowButton,
  LargeH1,
  Subtitle
} from '../components/styled'
import { PageHero } from '../components/PageHero'
import { LessonPiecesWidget } from '../components/lessons/LessonsWidget'
import { FRONTEND } from '../constants'
import { PlatformWidget } from '../components/platform/PlatformWidget'
import { SupportWidget } from '../components/support/SupportWidget'
import { Button } from 'antd'

import { ClientSummary } from '../components/clients/ClientSummary'
import { VideoFrameWithMute, PROMO_HLS } from '../components/Video'
import { SEO } from '../components/SEO'
import { VideoModal } from '../components/VideoModal'

const IndexContent: React.FC = () => {
  const [email, setEmail] = React.useState('')
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <VideoModal
        onVisibleChange={setVisible}
        hlsUrl={PROMO_HLS}
        visible={visible}
        muteDefault={false}
      />
      <SEO />
      <Container>
        <PageHero
          rightContent={
            <BrochureRight>
              <VideoFrameWithMute width={600} muted autoplay loop hlsUrl={PROMO_HLS} />
            </BrochureRight>
          }
        >
          <LargeH1>Where Every Child is Exceptional</LargeH1>
          <Subtitle>
            Engaging, interactive, standards-based, customizable curriculum for K-5 General Music
            Education.
          </Subtitle>
          <HideOn size={'md'}>
            <Block marginBottom={'@size-s'} marginTop={'@size-s'}>
              <Button
                type={'link'}
                icon={<PlayCircleOutlined />}
                onClick={() => setVisible(true)}
                style={{ paddingLeft: 0 }}
              >
                Watch our Video
              </Button>
            </Block>
          </HideOn>
          <Block marginTop={'@size-l'} marginBottom={'@size-s'}>
            <BorderInput
              prefix={<MailOutlined />}
              placeholder={'Enter your email address'}
              size={'large'}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Block>
          <HSpaced align={'center'} space={'@size-m'}>
            <GlowButton
              size={'large'}
              type={'primary'}
              onClick={() => {
                window.location.href = `${FRONTEND}/auth/trial-request${
                  email ? `?email=${encodeURIComponent(email)}` : ''
                }`
              }}
            >
              &nbsp;&nbsp;Start your Free Trial&nbsp;&nbsp;
            </GlowButton>
            <Body color={'@text-color-secondary'}>30-day trial. Full access.</Body>
          </HSpaced>
        </PageHero>
        <ClientSummary />
        <LessonPiecesWidget />
      </Container>
      <PlatformWidget />
      <Container>
        <SupportWidget />
      </Container>
    </>
  )
}

const BrochureRight = styled(BrochureCard)`
  width: calc(100% + ${(props) => props.theme['@size-xxl']});
  position: relative;
  overflow: hidden;
  border: solid 10px white;
  min-width: 300px;
  &:before {
    content: ' ';
    display: block;
    padding-bottom: 56.25%;
  }
  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
    height: 100% !important;
  }
`

const IndexPage: React.FC = () => <IndexContent />

export default IndexPage
